import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/insights/esg-ratings-action/*" />
    <RAC path="/zh-cn/insights/esg-ratings-action/*" />
    <RAC path="/zh-tw/insights/esg-ratings-action/*" />
    <RAC path="/pl/insights/esg-ratings-action/*" />
    <RAC path="/ru/insights/esg-ratings-action/*" />
    <RAC path="/it/insights/esg-ratings-action/*" />
    <RAC path="/de/insights/esg-ratings-action/*" />
    <RAC path="/es/insights/esg-ratings-action/*" />
    <RAC path="/fr/insights/esg-ratings-action/*" />
    <RAC path="/ar/insights/esg-ratings-action/*" />
    <RAC path="/id/insights/esg-ratings-action/*" />
    <RAC path="/pt/insights/esg-ratings-action/*" />
    <RAC path="/ko/insights/esg-ratings-action/*" />
    <DynamicNotFoundPage default />
  </Router>
)
